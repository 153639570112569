import plugin from "tailwindcss/plugin";

export default plugin(({ theme, addComponents }) => {
  addComponents({
    ".circle-icon": {
      boxSizing: "content-box",
      width: "32px",
      height: "32px",
      flexShrink: "0",
      padding: `calc(${theme("spacing.xs")} - 2px)`,

      border: "1px solid currentColor",
      borderRadius: theme("borderRadius.full"),

      "@media (min-width: 640px)": {
        padding: theme("spacing.xs"),
      },
    },
  });
});

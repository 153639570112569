"use client";

import { Slot } from "@radix-ui/react-slot";
import {
  type HTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { useIsReducedMotion } from "~utils";
import type { IconName } from "../icon";
import { IconButton } from "../icon-button";

type RailsItemState = {
  id: string;
  el: Element;
  inView: "partial" | "full" | null;
};

type RailsContextProps = {
  viewportEl: Element | null | undefined;
  items: RailsItemState[];
  setViewportEl: (el: Element | null | undefined) => void;
  updateItem: (item: RailsItemState) => string;
  removeItem: (id: string) => void;
  goTo: (index: number) => void;
};

export const RailsContext = createContext<RailsContextProps>({
  viewportEl: null,
  items: [],
  setViewportEl: () => {},
  updateItem: () => "",
  removeItem: () => {},
  goTo: () => {},
});

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/

type RailsRootProps = {
  children: ReactNode;
  className?: string;
  asChild?: boolean;
  id?: string;
};

export function RailsRoot({ asChild, ...props }: RailsRootProps) {
  const Comp = asChild ? Slot : "section";
  const [viewportEl, setViewportEl] = useState<Element | null | undefined>();
  const isReducedMotion = useIsReducedMotion();

  const [items, setItems] = useState<RailsItemState[]>([]);

  const updateItem: RailsContextProps["updateItem"] = useCallback(item => {
    setItems(prev => {
      if (!prev.some(existingItem => existingItem.id === item.id)) {
        return [...prev, item];
      }

      return prev.map(existingItem =>
        existingItem.id === item.id ? item : existingItem,
      );
    });

    return item.id;
  }, []);

  const removeItem = useCallback(
    (id: string) => setItems(prev => prev.filter(item => item.id !== id)),
    [],
  );

  const goTo = useCallback(
    (targetIndex: number) => {
      if (!viewportEl) return;

      const targetItem = items[targetIndex];
      if (!targetItem) return;

      const viewportStyle = window.getComputedStyle(viewportEl, null);
      const viewportRect = viewportEl.getBoundingClientRect();
      const targetItemRect = targetItem.el.getBoundingClientRect();

      // The inner position of the viewport - inside padding and/or scroll padding.
      const viewportOffset =
        viewportRect.left +
        parseStyleValue(viewportStyle.borderLeftWidth || "") +
        // Take only the higher value of padding or scroll padding, as they overlap conceptually.
        Math.max(
          parseStyleValue(viewportStyle.paddingLeft || ""),
          parseStyleValue(viewportStyle.scrollPaddingLeft || ""),
        );

      const scrollPosition = Math.floor(
        viewportEl.scrollLeft + (targetItemRect.left - viewportOffset),
      );

      viewportEl.scrollTo({
        left: scrollPosition,
        behavior: isReducedMotion ? "instant" : "smooth",
      });
    },
    [items, isReducedMotion, viewportEl],
  );

  const contextValue = useMemo(
    () => ({
      viewportEl,
      setViewportEl,
      items,
      goTo,
      updateItem,
      removeItem,
    }),
    [viewportEl, items, goTo, updateItem, removeItem],
  );

  return (
    <RailsContext.Provider value={contextValue}>
      <Comp {...props} />
    </RailsContext.Provider>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Viewport
 * -----------------------------------------------------------------------------------------------*/

type RailsViewportProps = {
  children: ReactNode;
  asChild?: boolean;
  className?: string;
};

export function RailsViewport({ asChild, ...props }: RailsViewportProps) {
  const Comp = asChild ? Slot : "div";

  const context = useContext(RailsContext);

  return <Comp {...props} ref={context.setViewportEl} />;
}

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/

type RailsItemProps = {
  children: ReactNode;
  className?: string;
  asChild?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function RailsItem({ asChild, ...props }: RailsItemProps) {
  const context = useContext(RailsContext);

  /**
   * Keeps a unique reference to the item on the context.
   * Useful for getting state and to remove the item again.
   */
  const id = useId();
  const itemState = context.items.find(item => item.id === id);

  /**
   * Uses the 3rd party library "react-intersection-observer" to add a change listener
   * when the current item intersection state changes.
   * The context then keeps track of this state for use in the other Rails components.
   */
  const { ref } = useInView({
    onChange: (inView, entry) => {
      const inViewState =
        entry.intersectionRatio === 1 ? "full" : inView ? "partial" : null;

      context.updateItem({
        id,
        inView: inViewState,
        el: entry.target,
      });
    },
    root: context.viewportEl,
    // Sends change events when the item is above 0% visible and 100% visible.
    threshold: [0, 1],
    // Extra margin to make the logic less brittle (as sub-pixel calculations can be tricky)
    rootMargin: "0px 1px 0px 1px",
  });

  // On unmount, make sure to remove the item from context.
  useEffect(() => {
    return () => context.removeItem(id);
  }, [id, context.removeItem]);

  const Comp = asChild ? Slot : "div";

  return (
    /**
     * Use the data-inview prop to apply intersection related styles.
     * Just be careful with size changes (zoom etc.), as they can cause the view state to change,
     * leading to a "flicker loop" ([data-inview="full"] -> zoom -> [data-inview="partial"] -> no zoom -> [data-inview="full"] -> zoom...).
     */
    <Comp ref={ref} data-inview={itemState?.inView ?? undefined} {...props} />
  );
}

type RailsControlAction = "prev" | "next" | "first" | "last";

/* -------------------------------------------------------------------------------------------------
 * Control
 * -----------------------------------------------------------------------------------------------*/

type RailsControlProps = {
  action: RailsControlAction;
  asChild?: boolean;
  children?: ReactNode;
  className?: string;
  isDisabled?: boolean;
  onDisabledChanged?: (isDisabled: boolean) => void;
} & HTMLAttributes<HTMLButtonElement>;

/*
 * A pagination control to go to first, prev, next or last item.
 * By default styled as a IconButton with arrow icon.
 */
export function RailsControl({
  asChild,
  action: type,
  isDisabled,
  onDisabledChanged,
  ...props
}: RailsControlProps) {
  const context = useContext(RailsContext);

  const canActivatePrev = useRef<boolean | null>(null);
  const isDisabledManaged = !!onDisabledChanged;

  const handleClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    ev => {
      const targetIndex = getActionTargetIndex(type, context.items);
      context.goTo(targetIndex);

      props.onClick?.(ev);
    },
    [context.items, context.goTo, type, props.onClick],
  );

  const canActivate = useMemo(() => {
    if (type === "first" || type === "prev") {
      const isAtStart = context.items[0]?.inView === "full";

      return !isAtStart;
    }

    const isAtEnd = context.items[context.items.length - 1]?.inView === "full";
    return !isAtEnd;
  }, [context.items, type]);

  useEffect(() => {
    if (canActivatePrev.current !== canActivate) {
      onDisabledChanged?.(!canActivate);
    }

    canActivatePrev.current = canActivate;
  }, [canActivate, onDisabledChanged]);

  if (asChild) {
    const extendedProps = {
      ...props,
      disabled: isDisabledManaged ? isDisabled : !canActivate,
    };
    return <Slot {...extendedProps} onClick={handleClicked} />;
  }

  return (
    <IconButton
      variant="secondary"
      name={getActionIconName(type)}
      disabled={isDisabledManaged ? isDisabled : !canActivate}
      {...props}
      onClick={handleClicked}
    />
  );
}

function getActionIconName(action: RailsControlAction): IconName {
  switch (action) {
    case "first":
      return "CaretDoubleLeft";
    case "prev":
      return "ArrowLeft";
    case "next":
      return "ArrowRight";
    case "last":
      return "CaretDoubleRight";
    default:
      throw new Error("Unsupported type");
  }
}

/**
 * Gets the index of the item that should be activated when using the selected action.
 */
function getActionTargetIndex(
  targetAction: RailsControlAction,
  items: RailsItemState[],
) {
  if (targetAction === "first") return 0;
  if (targetAction === "last") return items.length - 1;

  if (targetAction === "prev") {
    const fullyInViewIndex = items.findIndex(item => item.inView === "full");
    if (fullyInViewIndex !== -1) return fullyInViewIndex - 1;

    const partialInviewIndex = items.findIndex(
      item => item.inView === "partial",
    );
    if (partialInviewIndex !== -1) return partialInviewIndex;

    return 0;
  }

  if (targetAction === "next") {
    const fullyInViewIndex = items.findIndex(item => item.inView === "full");
    if (fullyInViewIndex !== -1) return fullyInViewIndex + 1;

    const partialInviewIndex = items.findIndex(
      item => item.inView === "partial",
    );
    if (partialInviewIndex !== -1) return partialInviewIndex + 1;

    return 0;
  }

  throw new Error("Unsupported type");
}

function parseStyleValue(styleValue: string) {
  if (!styleValue || styleValue === "auto") return 0;
  const value = Number.parseFloat(styleValue || "");
  return Number.isNaN(value) ? 0 : value;
}

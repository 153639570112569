/**
 * Config for all the relations that should be resolved when fetching storyblok stories.
 *
 * Avoid putting too complex relations on this, as it can affect API performance.
 */
export const globalRelations = [
  "ArticlePage.author",
  "PersonGallery.references",
  "MigrationPersonGridSection.references",
];

/**
 * Config for all the relations that should be resolved when fetching storyblok sub pages.
 * Keep this very light, as we can fetch hundreds of subpages at a time.
 */
export const subPageRelations = [];

import { getFlagIcon } from "~utils";

/**
 * The supported locales of our application.
 *
 * Composition:
 *           da                -                 DK
 * [ISO 639-1 language code | dash | ISO 3166-1 country code (uppercase)]
 *
 * "en" is a special case of "international" english, used for two purposes:
 * a) on "global", it acts as the default english locale.
 * b) for local markets, it acts as an editor-only reference for translated content.
 *
 * https://en.wikipedia.org/wiki/ISO_639
 * https://en.wikipedia.org/wiki/ISO_3166-1
 */
export const locales = [
  "en",
  "bg-BG",
  "cs-CZ",
  "da-DK",
  "de-DE",
  "es-ES",
  "et-EE",
  "fi-FI",
  "fr-FR",
  "hu-HU",
  "lt-LT",
  "lv-LV",
  "pl-PL",
  "ro-RO",
  "uk-UA",
] as const;
export type Locale = (typeof locales)[number];

// All locales that have been migrated from WordPress to storyblok setup.
export const internalLocales: Locale[] = [
  "en",
  "cs-CZ",
  "de-DE",
  "fr-FR",
  "pl-PL",
  "ro-RO",
  "uk-UA",
];

/**
 * Our supported market ids, using lowercase ISO 3166-1 country codes.
 * https://en.wikipedia.org/wiki/ISO_3166-1
 *
 * `global` is a custom id, used as fallback for regions that don't have their own content for a specific page.
 */
export const marketIds = [
  "global",
  "bg",
  "cz",
  "dk",
  "de",
  "ee",
  "es",
  "fi",
  "fr",
  "hu",
  "lt",
  "lv",
  "pl",
  "ro",
  "ua",
] as const;
export type MarketId = (typeof marketIds)[number];

export type Market = {
  name: string;
  // biome-ignore lint/suspicious/noExplicitAny: The real type is hidden in Next internals
  icon: any;
  locales: Locale[] | typeof locales;
  url: string;
};

/**
 * Settings for each market.
 */
export const marketsLookup: Record<MarketId, Market> = {
  global: {
    icon: getFlagIcon("uk"),
    name: "United Kingdom",
    locales: internalLocales,
    url: "/",
  },
  bg: {
    icon: getFlagIcon("bg"),
    name: "България",
    locales: ["bg-BG"],
    url: "https://agreena.com/bg/",
  },
  cz: {
    icon: getFlagIcon("cz"),
    name: "Česko",
    locales: ["cs-CZ"],
    // For some reason WP site uses Czech country code, not language code.
    url: "/cs-cz",
  },
  dk: {
    icon: getFlagIcon("dk"),
    name: "Danmark",
    locales: ["da-DK"],
    url: "https://agreena.com/da/",
  },
  de: {
    icon: getFlagIcon("de"),
    name: "Deutschland",
    locales: ["de-DE"],
    url: "/de-de",
  },
  ee: {
    icon: getFlagIcon("ee"),
    name: "Eesti",
    locales: ["et-EE"],
    url: "https://agreena.com/et/",
  },
  es: {
    icon: getFlagIcon("es"),
    name: "España",
    locales: ["es-ES"],
    url: "https://agreena.com/es/",
  },
  fr: {
    icon: getFlagIcon("fr"),
    name: "France",
    locales: ["fr-FR"],
    url: "/fr-fr",
  },
  lv: {
    icon: getFlagIcon("lv"),
    name: "Latvija",
    locales: ["lv-LV"],
    url: "https://agreena.com/lv/",
  },
  lt: {
    icon: getFlagIcon("lt"),
    name: "Lietuva",
    locales: ["lt-LT"],
    url: "https://agreena.com/lt/",
  },
  hu: {
    icon: getFlagIcon("hu"),
    name: "Magyarország",
    locales: ["hu-HU"],
    url: "https://agreena.com/hu/",
  },
  pl: {
    icon: getFlagIcon("pl"),
    name: "Polska",
    locales: ["pl-PL"],
    url: "/pl-pl",
  },
  ro: {
    icon: getFlagIcon("ro"),
    name: "România",
    locales: ["ro-RO"],
    url: "/ro-ro",
  },
  fi: {
    icon: getFlagIcon("fi"),
    name: "Suomi",
    locales: ["fi-FI"],
    url: "https://agreena.com/fi/",
  },
  ua: {
    icon: getFlagIcon("ua"),
    name: "Україна",
    locales: ["uk-UA"],
    url: "/uk-ua",
  },
};

export const markets = Object.entries(marketsLookup).map(([key, value]) => ({
  key: key as MarketId,
  ...value,
}));
